import React from 'react';
import { graphql } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Image from '../../../components/Image';
import Hero from '../../../components/Hero';
import SubfooterNav from '../../../components/SubfooterNav';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/bg_biomed_texture.jpg';
import Icon from '../../../components/Icon';
import '../../../styles/pages/_unit-sections.scss';

const BiomedUnit4 = ({ data }) => {
  const heroBreadcrumbs = {
    unitLabel: 'UNIT 4',
    linkLabel: 'BIOMED COURSE',
    to: '/classroom-instruction/biomed',
  };

  const {
    bmBehindScientificBreakthroughs,
    bmCellularAging,
    bmGeneticSequencing,
    bmHumanGenomeProject,
    bmCanOrganismHaveNoParents,
    bmBioengineeringOrganisms,
    bmBioengineeringPlants,
    bmTherapeuticCloningEmbryonicStemCells,
    bmLongevityMarkers,
    bmSenolyticsOurWarAgainstAging,
    bmEthicalConsiderationsLongevity,
  } = getKeyedResources(data);

  return (
    <Layout title="Biomed Unit 4">
      <Hero
        className={'hero--small course__hero'}
        modal
        imageSource="header-biomed-unit4.jpg"
        guidSrc="ee52abd7-f21d-4c28-b998-2cbbece70861"
        posterSrc={'unit-4-biomed-intro-video-still.gif'}
        breadcrumbs={heroBreadcrumbs}
        color={'orange'}
        playtext={'Video Intro'}
      >
        <h1 className=" mb-0 pb-0">
          Behind the Scenes of Scientific Breakthroughs
        </h1>
        <p className="pb-2">
          How can we use biotechnology to increase longevity and improve the
          quality of life for an aging population, while possibly extending the{' '}
          <br />
          lifespan of humans?
        </p>
      </Hero>
      <Section className="living-earth__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="living-earth__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            <div>BIOMED | UNIT 4</div>
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                STORYLINE
              </h3>
              <ul className="course__list course__list-style-none pr-2">
                <li className="mr-3">
                  Explore the idea of increasing the human lifespan, then
                  examine scientific breakthroughs and careers that advance
                  human health goals.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                PACING
              </h3>
              <ul className="course__list">
                <li>¼ School Year</li>
                <li>55 Days</li>
                <li>3 Flex Days Built In</li>
                <li>45-minute Classes</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                FRAMING
              </h3>
              <ul className="course__list">
                <li>Standards Aligned</li>
                <li>Guiding Question</li>
                <li>Engineering Design Process</li>
                <li>Project Based</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-lesson-plans-labs.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                LESSON PLANS AND LABS
              </h3>
              <ul className="course__list">
                <li>9 Lessons with Lab Elements</li>
                <li>1 Core Lab </li>
                <li>1 Final Project </li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">
                Lesson Plans, Labs and Student Capture Sheets
              </h3>
              <p>
                This unit includes lesson plans, labs, a student interactive and
                student capture sheets that empower educators to engage all
                students. This lesson bundle can be downloaded for use in the
                classroom.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-BM-U4.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 16.7 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        <Container fullWidth>
          <Row>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmBehindScientificBreakthroughs.tophat}
                tophatClass={`${bmBehindScientificBreakthroughs.page}__resource-tophat`}
                ribbon={bmBehindScientificBreakthroughs.ribbon}
                ribbonClass={`${bmBehindScientificBreakthroughs.page}__resource-ribbon`}
                img=""
                duration={bmBehindScientificBreakthroughs.duration}
                subject={bmBehindScientificBreakthroughs.subject}
                subjectClass={`${bmBehindScientificBreakthroughs.page}__resource-subject`}
                title={bmBehindScientificBreakthroughs.title}
                description={bmBehindScientificBreakthroughs.description}
                actions={bmBehindScientificBreakthroughs.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmCellularAging.tophat}
                tophatClass={`${bmCellularAging.page}__resource-tophat`}
                ribbon={bmCellularAging.ribbon}
                ribbonClass={`${bmCellularAging.page}__resource-ribbon`}
                img=""
                duration={bmCellularAging.duration}
                subject={bmCellularAging.subject}
                subjectClass={`${bmCellularAging.page}__resource-subject`}
                title={bmCellularAging.title}
                description={bmCellularAging.description}
                actions={bmCellularAging.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmGeneticSequencing.tophat}
                tophatClass={`${bmGeneticSequencing.page}__resource-tophat`}
                ribbon={bmGeneticSequencing.ribbon}
                ribbonClass={`${bmGeneticSequencing.page}__resource-ribbon`}
                img=""
                duration={bmGeneticSequencing.duration}
                subject={bmGeneticSequencing.subject}
                subjectClass={`${bmGeneticSequencing.page}__resource-subject`}
                title={bmGeneticSequencing.title}
                description={bmGeneticSequencing.description}
                actions={bmGeneticSequencing.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmHumanGenomeProject.tophat}
                tophatClass={`${bmHumanGenomeProject.page}__resource-tophat`}
                ribbon={bmHumanGenomeProject.ribbon}
                ribbonClass={`${bmHumanGenomeProject.page}__resource-ribbon`}
                img=""
                duration={bmHumanGenomeProject.duration}
                subject={bmHumanGenomeProject.subject}
                subjectClass={`${bmHumanGenomeProject.page}__resource-subject`}
                title={bmHumanGenomeProject.title}
                description={bmHumanGenomeProject.description}
                actions={bmHumanGenomeProject.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmCanOrganismHaveNoParents.tophat}
                tophatClass={`${bmCanOrganismHaveNoParents.page}__resource-tophat`}
                ribbon={bmCanOrganismHaveNoParents.ribbon}
                ribbonClass={`${bmCanOrganismHaveNoParents.page}__resource-ribbon`}
                img=""
                duration={bmCanOrganismHaveNoParents.duration}
                subject={bmCanOrganismHaveNoParents.subject}
                subjectClass={`${bmCanOrganismHaveNoParents.page}__resource-subject`}
                title={bmCanOrganismHaveNoParents.title}
                description={bmCanOrganismHaveNoParents.description}
                actions={bmCanOrganismHaveNoParents.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmBioengineeringOrganisms.tophat}
                tophatClass={`${bmBioengineeringOrganisms.page}__resource-tophat`}
                ribbon={bmBioengineeringOrganisms.ribbon}
                ribbonClass={`${bmBioengineeringOrganisms.page}__resource-ribbon`}
                img=""
                duration={bmBioengineeringOrganisms.duration}
                subject={bmBioengineeringOrganisms.subject}
                subjectClass={`${bmBioengineeringOrganisms.page}__resource-subject`}
                title={bmBioengineeringOrganisms.title}
                description={bmBioengineeringOrganisms.description}
                actions={bmBioengineeringOrganisms.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmBioengineeringPlants.tophat}
                tophatClass={`${bmBioengineeringPlants.page}__resource-tophat`}
                ribbon={bmBioengineeringPlants.ribbon}
                ribbonClass={`${bmBioengineeringPlants.page}__resource-ribbon`}
                img=""
                duration={bmBioengineeringPlants.duration}
                subject={bmBioengineeringPlants.subject}
                subjectClass={`${bmBioengineeringPlants.page}__resource-subject`}
                title={bmBioengineeringPlants.title}
                description={bmBioengineeringPlants.description}
                actions={bmBioengineeringPlants.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmTherapeuticCloningEmbryonicStemCells.tophat}
                tophatClass={`${bmTherapeuticCloningEmbryonicStemCells.page}__resource-tophat`}
                ribbon={bmTherapeuticCloningEmbryonicStemCells.ribbon}
                ribbonClass={`${bmTherapeuticCloningEmbryonicStemCells.page}__resource-ribbon`}
                img=""
                duration={bmTherapeuticCloningEmbryonicStemCells.duration}
                subject={bmTherapeuticCloningEmbryonicStemCells.subject}
                subjectClass={`${bmTherapeuticCloningEmbryonicStemCells.page}__resource-subject`}
                title={bmTherapeuticCloningEmbryonicStemCells.title}
                description={bmTherapeuticCloningEmbryonicStemCells.description}
                actions={bmTherapeuticCloningEmbryonicStemCells.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmLongevityMarkers.tophat}
                tophatClass={`${bmLongevityMarkers.page}__resource-tophat`}
                ribbon={bmLongevityMarkers.ribbon}
                ribbonClass={`${bmLongevityMarkers.page}__resource-ribbon`}
                img=""
                duration={bmLongevityMarkers.duration}
                subject={bmLongevityMarkers.subject}
                subjectClass={`${bmLongevityMarkers.page}__resource-subject`}
                title={bmLongevityMarkers.title}
                description={bmLongevityMarkers.description}
                actions={bmLongevityMarkers.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmSenolyticsOurWarAgainstAging.tophat}
                tophatClass={`${bmSenolyticsOurWarAgainstAging.page}__resource-tophat`}
                ribbon={bmSenolyticsOurWarAgainstAging.ribbon}
                ribbonClass={`${bmSenolyticsOurWarAgainstAging.page}__resource-ribbon`}
                img=""
                duration={bmSenolyticsOurWarAgainstAging.duration}
                subject={bmSenolyticsOurWarAgainstAging.subject}
                subjectClass={`${bmSenolyticsOurWarAgainstAging.page}__resource-subject`}
                title={bmSenolyticsOurWarAgainstAging.title}
                description={bmSenolyticsOurWarAgainstAging.description}
                actions={bmSenolyticsOurWarAgainstAging.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmEthicalConsiderationsLongevity.tophat}
                tophatClass={`${bmEthicalConsiderationsLongevity.page}__resource-tophat`}
                ribbon={bmEthicalConsiderationsLongevity.ribbon}
                ribbonClass={`${bmEthicalConsiderationsLongevity.page}__resource-ribbon`}
                img=""
                duration={bmEthicalConsiderationsLongevity.duration}
                subject={bmEthicalConsiderationsLongevity.subject}
                subjectClass={`${bmEthicalConsiderationsLongevity.page}__resource-subject`}
                title={bmEthicalConsiderationsLongevity.title}
                description={bmEthicalConsiderationsLongevity.description}
                actions={bmEthicalConsiderationsLongevity.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 pt-0">
        <SubfooterNav
          current={{
            name: 'Unit 4',
            link: '/classroom-instruction/biomed/unit-4',
          }}
          next={{
            name: 'Overview',
            link: '/classroom-instruction/biomed',
          }}
          previous={{
            name: 'Unit 3',
            link: '/classroom-instruction/biomed/unit-3',
          }}
        />
      </Section>
    </Layout>
  );
};

export default BiomedUnit4;

export const query = graphql`
  query BiomedUnit4Resources {
    allResourcesJson(filter: { page: { eq: "biomed" }, unit: { eq: 4 } }) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
